var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-card',[_c('v-card-title',[_vm._v("Usage")]),_c('v-card-text',[_c('div',{staticClass:"flex justify-between"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Button ")])]}}])},[_c('span',[_vm._v("Tooltip")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-home ")])]}}])},[_c('span',[_vm._v("Tooltip")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("This text has a tooltip")])]}}])},[_c('span',[_vm._v("Tooltip")])])],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-card',[_c('v-card-title',[_vm._v("Alignment")]),_c('v-card-text',[_c('div',{staticClass:"flex justify-between"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Left ")])]}}])},[_c('span',[_vm._v("Left tooltip")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Top ")])]}}])},[_c('span',[_vm._v("Top tooltip")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Bottom ")])]}}])},[_c('span',[_vm._v("Bottom tooltip")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Right ")])]}}])},[_c('span',[_vm._v("Right tooltip")])])],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-card',[_c('v-card-title',[_vm._v("Visibility")]),_c('v-card-text',[_c('v-row',{staticClass:"flex",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{on:{"click":function($event){_vm.show = !_vm.show}}},[_vm._v(" toggle ")])],1),_c('v-col',{staticClass:"mt-12",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-cart ")])],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('span',[_vm._v("Programmatic tooltip")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }